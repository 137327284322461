.roguelike-container {
    margin: 30px auto auto auto;
    width: 80vw;
    height: 90vh;
}

.roguelike-container .ant-progress-circle .ant-progress-text {
    color: #ffffff;
}

.roguelike-container .no-display {
    display: none;
}

.roguelike-container .unity-app {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}