@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

