.full-page-image-container {
    height: 100vh;
    background-size: cover;
    background-position-x: 20%;
    min-height: 100%;
    position: relative;
}

.full-page-image-caption {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    opacity: 0.55;
    height: 100%;
}

.full-page-image-span {
    padding: 12px 18px;
    color: #FFFFFF;
    font-size: 35px;
    background-color: #111111;
    letter-spacing: 3px;
    word-spacing: 3px;
    border-radius: 3px;
}