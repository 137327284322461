body {
    overflow-y: scroll;
    letter-spacing: 0px;
}

.main-title {
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 28px;
    text-align: center;
}

.main-description {
    text-align: center;
    font-size: 20px;
}

.project .ant-image {
    overflow: hidden;
}

.project .ant-image img {
    transition: all 0.5s;
}

.project .ant-image:hover img {
    -ms-transform: scale(1.3); /* IE 9 */
    -webkit-transform: scale(1.3); /* Safari 3-8 */
    transform: scale(1.3);
}

.see-more ul {
    list-style: disc;
    padding-left: 20px;
}